@import "../ rectangle/rectangle.module";
.wrapper{
  @extend .rectangle;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 3px dashed rgba(149, 210, 232, 0.78);

  &:hover{
    background-color: rgba(239, 236, 236, 0.29);
  }
  select{
    margin-top: 20px;
  }
}

.addButton{
  color: #76d9fa;
  width: 80px;
  font-size: 30px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border-radius: 50px;
  border: 3px dashed rgba(149, 210, 232, 0.78);

}