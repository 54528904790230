#header{
 text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
  font-size: 50px;
 margin-bottom: 0;
 color: #ffd864;
 background: #3b4b52;
 margin-top: 0;
 padding: 40px 0;
 align-items: center;
 img{
   width: 50px;
   height: 50px;
   margin-left: 20px;
 }
}