@import "../ rectangle/rectangle.module";

.wrapper{
  @extend .rectangle;
  display: flex;
  border: 1px solid #adacac;
  flex-direction: column;
  position: relative;
  p{
    margin: 0;
    padding: 10px 0;
    background-color: #fafcfc;
    text-align: center;
  }
  iframe{
    border: none;
    height: 100%;
    width: 100%;
  }
  .deleteDialer{
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    cursor: pointer;
  }
}