.content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.buttons{
  display: flex;
  padding: 20px 0;
  gap: 20px;
  button{
    background-color: rgb(250, 212, 0);
    border: none;
    border-radius: 50px;
    padding: 5px 15px;
    color: black;
    font-size: 15px;
    cursor: pointer;
    width: 150px;
    box-sizing: content-box;
    height: 40px;
    &:hover{
      opacity: .8;
    }
  }
}

.wrapper{
  display: flex;
  flex-wrap: wrap;
  padding: 0 50px ;
  gap: 20px;

}